@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --primary: "#2D947A";
  --secondary: "#263959";
  --text-color: "#696E77";
}
body {
  font-family: "DM Sans", sans-serif;
  background-color: #fff;
}
.containers {
  max-width: 85%;
  margin: auto;
}
input {
  border: none;
  outline: none;
}
p {
  color: var(--text-color);
}
.desc-div {
  line-height: 40px;
  font-size: 17px;
}

header nav ul li .active {
  color: #fba311;
}

/* home */
.home .box {
  position: relative;
  height: 85vh;
  width: 100%;
}
.home .box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 85vh;
  background-color: #000;
  opacity: 0.5;
  z-index: 1;
}
.home .text {
  z-index: 2;
}
.slick-dots ul li.slick-active div {
  border: 2px solid #fba311 !important;
}
.slick-dots ul li.slick-active span {
  background: #fba311 !important;
}
